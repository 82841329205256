@font-face {
    font-family: 'FuturaPT';
    src: url('/assets/fonts/FuturaPT/FuturaPTMedium.otf') format("opentype");
}

@font-face {
    font-family: 'FuturaPT-Book';
    src: url('/assets/fonts/FuturaPT/FuturaPTBook.otf') format("opentype");
}

@font-face {
    font-family: 'FuturaPT-Light';
    src: url('/assets/fonts/FuturaPT/FuturaPTLight.otf') format("opentype");
}


@font-face {
    font-family: 'FuturaPT-Demi';
    src: url('/assets/fonts/FuturaPT/FuturaPTDemi.otf') format("opentype");
}

@font-face {
    font-family: 'FuturaNew-Obl';
    src: url('/assets/fonts/FuturaNewMediumOblique.otf') format("opentype");
}

@font-face {
    font-family: 'HelveticaNeue';
    src: url('/assets/fonts/HelveticaNeue/Helvetica-Neue-Bold.ttf') format("opentype");
}

@font-face {
    font-family: 'HelveticaNeue-Medium';
    src: url('/assets/fonts/HelveticaNeue/HelveticaNeue-Medium.ttf') format("opentype");
}

@font-face {
    font-family: 'HelveticaNeue-Regular';
    src: url('/assets/fonts/HelveticaNeue/helveticaneue.ttf') format("opentype");
}