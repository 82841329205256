@bp-mobile-max: 1024px;
@bp-desktop-min: @bp-mobile-max + 1px;
@bp-desktop-max: 10000px;
@bp-desktop-hd-min: @bp-desktop-max + 1px;

.center-item {
    margin: auto;
}

.warning-alert {
    padding: 7px 10px !important;
    font-family: 'FuturaPT';
    font-size: 16px;
    font-weight: 500;
    line-height: 1.19;
    color: #e31f26;
    display: block;
}

.warning-alert-input {
    border: solid 1.1px #e31f26 !important;
}

.vertical-state-line {
    border-left: solid 1px #d4d8e8 !important;
    border-radius: 0 !important;
    height: 33px !important;
}

.state-filter {
    width: 18px !important;
    min-width: 18px !important;
    box-sizing: border-box;
    background: url(/assets/common/images/upm-icons/state-filter-normal.png) 50% 50% no-repeat !important;
    background-size: 18px 12px;
    cursor: pointer;
}

.state-filter-active {
    box-sizing: border-box;
    background: url(/assets/common/images/upm-icons/state-filter-active.png) 50% 50% no-repeat !important;
    background-size: 18px 12px;
}

.popup {
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    .filters-popup {
        position: absolute;
        z-index: 2;
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 9px 10px 0 rgba(157, 184, 226, 0.27);
        border: solid 1px rgba(152, 155, 190, 0.3);
        margin-left: 0px;
        padding: 24px 17px;
        width: 214px;

        &.show {
            display: flex !important;
            -webkit-animation: fadeIn 0.5s forwards;
            animation: fadeIn 0.5s forwards;
        }

        &.hide {
            display: none !important;
            -webkit-animation: fadeOut 0.5s forwards;
            animation: fadeOut 0.5s forwards;
        }
    }
}

.popup .popuptext {
    visibility: hidden;
    width: 180px;
    height: 68px;
    object-fit: contain;
    border-radius: 5px;
    box-shadow: 0 8px 16px 0 rgba(243, 0, 0, 0.2);
    background-color: #e31f26;
    color: #fbfbfb;
    font-family: 'FuturaPT-Book';
    font-size: 15px;
    line-height: 1.19;
    text-align: center;
    padding: 8px;
    position: absolute;
    z-index: 1;
    bottom: -8px;
    left: -112px;
    margin-left: -80px;
    letter-spacing: 0.5px;
}

.popup .popuptext::after {
    content: "";
    position: absolute;
    top: 39%;
    left: 102%;
    margin-left: -5px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent transparent #e31f26;
}

.popup .show {
    visibility: visible;
    -webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
}

@-webkit-keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        display: none !important;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        display: none !important;
    }
}

textarea {
    min-height: 104px !important;
}

.card-background {
    background-color: white;
}

.btn-upm {
    min-height: 52px;
    border-radius: 100px;
    padding: 12px 24px;

    &.btn-upm-export {
        color: #79b835;
        background-color: white;
        border: solid 1.8px #79b835;
        box-shadow: 0 8px 16px 0 rgba(157, 200, 109, 0.62);
        color: white;

        &.disabled,
        &[disabled] {
            opacity: 0.65;
        }

        &.btn-upm-export-only-icon {
            margin-right: 13px;
            padding: 6px;
        }
    }

    &.btn-upm-green {
        color: white;
        background-color: #79b835;
        border: solid 1.8px #77ba27;
        box-shadow: 0 8px 16px 0 rgba(157, 200, 109, 0.62);

        &:hover {
            cursor: pointer;
            box-shadow: 0 8px 16px 0 rgba(157, 200, 109, 0.62);
            border: solid 1.8px #7cc229;
            background-color: #7ec037;
        }

        &:focus {
            box-shadow: 0 7px 18px 0 rgba(166, 196, 155, 0.5);
            border: solid 1.8px #69b304;
            background-color: #6bb121;
        }

        &.disabled,
        &[disabled] {
            opacity: 0.65;
        }
    }

    &.btn-upm-action {
        border: solid 0px #3b3e70;
        min-width: 145px;
        min-height: 36px;
        border-radius: 22px;
        background-color: #3b3e70;
        color: white;

        &:hover {
            cursor: pointer;
            background-color: #494d8c;
        }

        &:focus {
            background-color: #4a4d78;
        }

        span {
            font-size: 16.2px !important;
            font-family: 'HelveticaNeue-Regular' !important;
        }
    }

    span {
        font-family: 'FuturaPT-Light';
        font-size: 22.4px !important;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.9px;
        color: #ffffff;
    }

    &.btn-asset {
        min-height: 46px;
    }

    .mr-11 {
        margin-right: 7px;
    }
}


input.search-bar-input {
    width: 587px !important;
    min-height: 55.74px;
    border-radius: 26px;
    border: solid 1px #d4d8e8;
    padding: 12px 24px 11px 55px;
    font-size: 22.4px;
    font-family: 'FuturaPT-Book';
    line-height: normal;
    color: #9aa3b3;
    letter-spacing: 0.5px;
    background-image: url(assets/common/images/upm-icons/search-icon.svg);
    background-repeat: no-repeat;
    background-position: 24px 16px;
    outline: none !important;

    &::placeholder {
        color: rgba(154, 163, 179, 0.82)
    }

    &:focus {
        background-image: url(assets/common/images/upm-icons/search-icon-active.png) !important;
        background-repeat: no-repeat;
        background-position: 24px 16px;
        border: solid 1px #0089fe;
        color: rgba(73, 80, 87, 0.82);

        &::placeholder {
            color: rgba(73, 80, 87, 0.82);
        }
    }
}

.kt-subheader {

    &.kt-subheader-upm {
        padding: 0px 0px 25px 0px !important;
    }

    .kt-subheader__title {
        height: 33px;
        font-family: 'FuturaPT' !important;
        font-size: 25px !important;
        font-weight: 500 !important;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 2.2px !important;
        color: #252830 !important;
    }
}

.kt-content.kt-content-upm {
    padding: 0 !important;
    border-radius: 5px;
    box-shadow: 0 0 12px 0 rgba(191, 203, 209, 0.27);
    background-color: #ffffff;
}

.primeng-datatable-container.upm-table {
    .ui-table {
        table {
            thead {
                tr {
                    th {
                        background-color: transparent !important;
                        font-family: 'FuturaPT-Demi' !important;
                        font-size: 17px !important;
                        color: #6c7293 !important;
                        padding-left: 24px !important;
                        padding-right: 24px !important;

                        i.ui-sortable-column-icon {
                            width: 10px;

                            &::before {
                                content: "\e902";
                                font-size: 11px;
                                top: 17px;
                                position: absolute;
                            }
                        }
                    }
                }
            }

            tbody {
                tr {
                    border-top: solid 1px #f0f3ff !important;

                    td {
                        font-family: 'FuturaPT-Book' !important;
                        font-size: 19.4px !important;
                        color: #565e86 !important;
                        letter-spacing: 0.5px !important;
                        padding: 18px 24px !important;

                        label {
                            margin-bottom: 0px;

                            &.radio-label {
                                display: block;
                                position: relative;
                                padding-left: 20px;
                                cursor: pointer;
                                -webkit-user-select: none;
                                -moz-user-select: none;
                                -ms-user-select: none;
                                user-select: none;

                                input {
                                    position: absolute;
                                    opacity: 0;
                                    cursor: pointer;

                                    &:checked~.checkmark {
                                        background-color: #2196F3;
                                    }

                                    &:checked~.checkmark:after {
                                        display: block;
                                    }
                                }

                                .checkmark {
                                    position: absolute;
                                    top: 3px;
                                    left: 0;
                                    height: 13px;
                                    width: 13px;
                                    border: solid 0.7px #2196F3;
                                    background-color: white !important;
                                    border-radius: 50%;

                                    &:after {
                                        content: "";
                                        position: absolute;
                                        display: none;
                                        top: 3px;
                                        left: 3px;
                                        width: 5.4px;
                                        height: 5.4px;
                                        border-radius: 50%;
                                        background-color: #2196F3;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(even) {
                        background-color: white !important;
                    }

                    &:last-child {
                        border-bottom: solid 1px #f0f3ff !important;
                    }

                    &:nth-child(odd) {
                        background-color: #fbfcfd;
                    }

                    &:hover {
                        background-color: #f4f7fc !important;
                    }
                }
            }
        }

        .ui-table-scrollable-body {
            padding-bottom: 30px !important;

        }
    }

    .primeng-paging-container {
        padding: 18px 36px !important;

        .ui-paginator {
            text-align: left !important;
            height: 37px;

            a {
                width: 35.6px;
                height: 35.6px;
                border: solid 1.6px #f0f3ff;
                background-color: white;
                padding-top: 0;
                margin-right: 8.6px;

                span.ui-paginator-icon {
                    color: #979cb5 !important;
                    font-size: 18px;
                }
            }

            .ui-paginator-pages {
                .ui-paginator-page {
                    width: 35.6px;
                    height: 35.6px;
                    border: solid 1.6px #f0f3ff;
                    padding: 11px !important;
                    background-color: white;
                    color: #979cb5 !important;
                    margin-right: 8.6px !important;

                    &.ui-state-active {
                        box-shadow: 0 7px 18px 0 rgba(160, 186, 246, 0.5);
                        background-color: #0089fe !important;
                        border: solid 1.8px #0089fe;
                        color: white !important;
                    }
                }
            }

            /*.ui-dropdown {
                border-radius: 3px;
                border: solid 1px #ced2e5;
                height: 35px;
                position: absolute;
                right: 160px;
                bottom: 30px;
                max-height: 27px;
                min-width: 70px;

                .ui-dropdown-label {
                    padding: 0 !important;
                    margin: 0px 0px 0px 10px !important;
                    height: 25px;
                    max-width: 58px;
                    font-family: 'FuturaPT-Book' !important;
                    font-size: 18.1px !important;
                    color: #656c90 !important;
                }

                .ui-dropdown-trigger-icon {
                    top: 57%;
                    left: 45%;
                }
            }*/

        }

        .total-records-count {
            float: right !important;
            font-family: 'FuturaPT-Book' !important;
            font-size: 18.1px !important;
            color: #656c90 !important;
            bottom: 30px;
            left: -40px;
        }
    }
}

#p-table-assets {
    .ui-widget-header {
        border: solid 1px #e8edf4 !important;
    }

    .ui-table-scrollable-body {
        padding-bottom: 0 !important;
    }

    .ui-table-tbody {
        border: solid 1px #e8edf4 !important;
    }

    .ui-table table thead tr th {
        font-family: 'FuturaPt' !important;
        padding-top: 17px !important;
        padding-bottom: 17px !important;
        letter-spacing: 0.1px !important;

        .second-title {
            font-family: 'FuturaPT-Light';
            font-size: 16px;
            color: #6c7293
        }
    }
}

.select {
    position: relative;
    display: inline-block;
    width: 100%;
}

.select select {
    display: inline-block;
    width: 100%;
    cursor: pointer;
    outline: 0;
    background: #ffffff;
    color: #495057;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.select select::-ms-expand {
    display: none;
}

.select select:hover,
.select select:focus {
    color: #565e86;
    background: #ffffff;
}

.select select:disabled {
    opacity: 0.5;
    pointer-events: none;
}

.select_arrow {
    position: absolute;
    top: 18px;
    right: 21px;
    width: 12px;
    height: 12px;
    border: solid #6c7293;
    border-width: 0 1.2px 1.2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.select select:focus~.select_arrow {
    border-color: #0284fe;
}

.select select:disabled~.select_arrow {
    border-top-color: #cccccc;
}


table {
    &.table {
        &.purchase-info-table {
            margin-bottom: 0px;

            tr {

                &:last-child {
                    .ui-column-title {
                        display: none;
                    }
                }

                th {
                    border: 0px;
                    font-family: 'FuturaPT-Demi';
                    font-size: 11.5px;
                    color: #6c7293;
                    padding-top: 9px;
                    padding-bottom: 0px;
                    padding-left: 17px;
                    background-color: #f4f6fc;
                }

                td {
                    border: 0px;
                    font-family: 'FuturaPT-Light';
                    font-size: 13.7px;
                    color: #565e86;
                    background-color: #f4f6fc;
                    padding-top: 2px;
                    padding-bottom: 9px;
                    padding-left: 17px;
                }
            }
        }
    }
}


p-checkbox {
    padding-bottom: 21px;

    .ui-chkbox.ui-widget {
        width: 19.5px !important;
        height: 19.5px !important;
        box-shadow: 0 0 12px 0 rgba(191, 203, 209, 0.27) !important;

        .ui-chkbox-box {
            opacity: 1 !important;
            width: 19.5px !important;
            height: 19.5px !important;
            border: solid 1.5px #8790b7 !important;

            &.ui-state-active {
                border-radius: 3px !important;
                border: solid 0px #8790b7 !important;
                background-color: #0089fe;

                .ui-chkbox-icon {
                    top: 1.5px;
                    left: 0.5px;
                    color: white;
                }
            }

            .ui-chkbox-icon {
                color: white;
            }
        }
    }

    .ui-chkbox-label {
        margin-left: 11px;
        font-family: 'FuturaPT-Book';
        font-size: 18px;
        color: #6c7293;

        &.ui-label-active {
            font-family: 'FuturaPT-Demi';
        }
    }

    &:last-child {
        padding-bottom: 0px !important;
    }
}

.btn-icon-table {
    background-color: transparent !important;

    img {

        &.trash {

            &:hover {
                background: url(/assets/common/images/upm-icons/trash-hover.png) no-repeat;
            }
        }

        &.edit {

            &:hover {
                background: url(/assets/common/images/upm-icons/edit-hover.png) no-repeat;
            }
        }
    }
}

.form-container {
    //padding-bottom: 134px !important;

    .max-width-container {
        max-width: 862px !important;
        margin: auto;

        .col {
            padding-left: 0;
            padding-right: 0;
        }
    }

    form {
        margin: 0 auto !important;
        width: 562px;

        .form-group {
            max-width: 562px !important;
            margin-bottom: 26px !important;

            label {
                font-family: 'FuturaPT-Demi' !important;
                font-size: 17px !important;
                color: #6c7293 !important;

                .optional-input {
                    font-family: 'FuturaNew-Obl';
                    font-size: 16px !important;
                    font-weight: 500 !important;
                    letter-spacing: 0.3px !important;
                }
            }

            .form-control {
                padding: 12px 19px 14px 19px !important;
                border-radius: 3.6px;
                border: solid 1.1px #d1dbeb;
                background-color: #ffffff;
                width: 100%;
                height: 52px;
                font-family: 'FuturaPT-Book';
                font-size: 21px !important;
                color: #565e86 !important;

                &.disabled,
                &[disabled] {
                    border: solid 1.1px #d1dbeb !important;
                    background-color: #f9fbff !important;
                    color: #727d89 !important;
                }

                &:focus,
                &:hover {
                    border-color: #0284fe;
                }

                &.select-form {
                    padding-bottom: 10px !important;
                }
            }

            .btn-form-upm {
                width: calc(50% - 13px) !important;
                margin-left: 0px !important;
                height: 52px !important;
                border-radius: 5px !important;



                &.first-btn {
                    margin-right: 13px;
                    width: 50% !important;
                }

                &.create-btn {
                    border: solid 1.8px #77ba27;
                    background-color: #79b835;

                    &:hover {
                        border: solid 1.8px #7cc229;
                        background-color: #7ec037;
                    }

                    &:focus {
                        border: solid 1.8px #6bb121;
                        background-color: #6bb121;
                    }

                    &.create-btn-ready {
                        border: solid 2px #77ba27 !important;
                        background-color: white !important;
                        pointer-events: none !important;
                        cursor: none !important;
                        opacity: 1 !important;

                        span {
                            color: #79b835 !important;
                            font-family: 'FuturaPT-Book' !important;
                        }

                        .icon-create-btn-ready {
                            color: #79b835 !important;
                            position: relative !important;

                            &::before {
                                bottom: 3px !important;
                                position: absolute !important;
                                right: 3px !important;
                            }
                        }
                    }

                }

                &.cancel-btn {
                    border: solid 1.8px #b1b0bb;
                    background-color: #b1b0bb;

                    &:hover {
                        border: solid 1.8px #d0cfd3;
                        background-color: #d0cfd3;
                    }

                    &:focus {
                        border: solid 1.8px #9e9da6;
                        background-color: #9e9da6;
                    }
                }

                &.next-step-btn {
                    border-radius: 5px;
                    background-color: #0089fe !important;
                    color: white !important;

                    &:disabled {
                        opacity: 0.25 !important;
                        border-radius: 5px !important;
                        background-color: #c3c8db !important;
                        color: #242240 !important;
                    }
                }

                span {
                    font-family: 'FuturaPT-Light' !important;
                    font-size: 22.4px !important;
                    letter-spacing: 0.9px !important;
                    color: #ffffff !important;
                }
            }

            .back-to-panel-link {
                font-family: 'FuturaPT-Book';
                font-size: 22.4px !important;
                font-weight: normal !important;
                text-align: center;
                color: #6c7293 !important;
                text-decoration: underline !important;
                margin-top: 22px !important;
            }
        }
    }
}

p-calendar {
    width: 100%;

    &.warning-alert-input-date {
        .ui-calendar {
            border: solid 1.1px #e31f26 !important;
        }
    }

    &.disabled-input-date {
        .ui-calendar {
            border: solid 1.1px #d1dbeb !important;
            background-color: aliceblue !important;
        }
    }

    .ui-calendar {
        width: 100%;
        height: 52px;
        border: solid 1.1px #d1dbeb;
        border-radius: 3.6px;
        -webkit-transition: .2s;
        transition: .2s;

        &:hover {
            border-color: #0284fe;
        }

        input {
            padding: 12px 19px 14px 19px !important;
            border: solid 0px #d1dbeb;
            background-color: #ffffff;
            width: calc(100% - 58px);
            height: 100%;
            font-family: 'FuturaPT-Book';
            font-size: 21px !important;
            color: #565e86 !important;
        }

        .ui-calendar-button {
            background-color: transparent !important;
            width: 58px !important;
            background: url(/assets/common/images/upm-icons/date-input.png) 13px 8px no-repeat;
            border: 0px !important;

            .ui-button-icon-left {
                display: none !important;
            }

            &:focus {
                box-shadow: none !important;
            }
        }

        .ui-datepicker {
            border-radius: 12px !important;
            box-shadow: 0 9px 10px 0 rgba(157, 184, 226, 0.27) !important;
        }

        .ui-datepicker-calendar {

            tbody {

                td {

                    a {
                        width: 39.1px !important;
                        height: 39.1px !important;
                        font-size: 14.5px !important;
                        margin: auto !important;
                        border-radius: 50% !important;
                        font-weight: 500 !important;
                        line-height: 24px !important;

                        &:hover {
                            background-color: transparent !important;
                        }


                        &.ui-state-active {
                            background-color: #0089fe !important;
                            box-shadow: 0 7px 18px 0 rgba(160, 186, 246, 0.5) !important;
                            color: white !important;
                        }
                    }

                    &.ui-datepicker-today {
                        a {
                            &:hover {
                                background-color: #d0d0d0 !important;
                            }
                        }
                    }

                }
            }
        }
    }

    &.ui-inputwrapper-focus {
        .ui-calendar {
            -webkit-transition: .2s;
            transition: .2s;
            border-color: #0284fe;

            .ui-calendar-button {
                background: url(/assets/common/images/upm-icons/date-input-active.png) 13px 8px no-repeat;
            }
        }
    }
}

p-tabview {
    .ui-tabview-nav {
        margin: 0 auto !important;
        max-width: 862px !important;
        border-bottom: solid 1px #cfd4e9 !important;
        padding-left: 12px !important;
        border-radius: 0 !important;

        li {
            font-family: 'FuturaPT' !important;
            letter-spacing: 1px !important;
            background-color: white !important;
            border: none !important;
            outline: none !important;

            &.ui-state-disabled {
                opacity: 1 !important;
            }

            a {
                padding: 8px 19px !important;
                padding-bottom: 7px;

                span {
                    font-weight: 500 !important;
                    color: #6c7293 !important;
                    opacity: 0.45;
                }
            }

            &.ui-state-active {
                border: none !important;
                border-bottom: solid 1px #cfd4e9 !important;
                margin-bottom: 0 !important;

                a {
                    span {
                        border-bottom: 3px solid #0089fe !important;
                        color: #5a5f7a !important;
                        padding-bottom: 6px;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .ui-tabview-panels {
        margin: 0 auto !important;
        max-width: 862px !important;
        border: none !important;
        padding: 34px 0 0 0 !important;

    }
}

p-autoComplete {
    .ui-clickable {
        font-size: 12px;
    }

    &.warning-alert-input {
        border: none !important;

        .ui-autocomplete input.ui-autocomplete-input {
            border: solid 0.7px #e31f26 !important;
        }
    }

    .ui-autocomplete {
        position: relative;

        input.ui-autocomplete-input {
            padding: 9px 9px 9px 26px !important;
            border-radius: 3.6px;
            border: solid 0.7px #d1dbeb;
            background-color: #ffffff;
            width: 100%;
            height: 34px;
            font-family: 'FuturaPT-Book';
            font-size: 14px !important;
            color: #565e86 !important;
            background-image: url(assets/common/images/upm-icons/search-icon.svg);
            background-repeat: no-repeat;
            background-position: 7px 9.5px;
            background-size: 13px;

            &.disabled,
            &[disabled] {
                border: solid 0.7px #d1dbeb !important;
                background-color: #f9fbff !important;
                color: #727d89 !important;
            }

            &:focus,
            &:hover {
                border-color: #0284fe !important;

                &::placeholder {
                    color: rgba(73, 80, 87, 0.82);
                }
            }

            &.select-form {
                padding-bottom: 6px !important;
            }

            &::placeholder {
                color: rgba(154, 163, 179, 0.82)
            }
        }

        button.ui-autocomplete-dropdown {
            outline: none;
            position: absolute;
            height: 32px;
            right: 0px;
            bottom: 1px;
            background-color: transparent;
            border-color: transparent;
            color: #d1dbeb;

            &:hover {
                background-color: transparent;
                border-color: transparent;
                color: #0089fe;
            }

            &:active,
            &:focus {
                outline: none;
            }
        }
    }
}

.deposit-info-container {
    margin-top: 22px;
    min-height: 110px;
    padding: 19px 16px;
    border-radius: 3px;
    border: solid 0.7px #e8edf4;
    background-color: rgba(233, 237, 255, 0.19);

    .deposit-info-title-container {
        margin-bottom: 16px;

        .deposit-info-title {
            border-radius: 12px;
            background-color: #0089fe;
            font-family: 'FuturaPT-Book';
            font-size: 12px;
            color: #ffffff;
            min-height: 18px;
            min-width: 77px;
            letter-spacing: 0.5px;
            padding: 3px 12px;
        }
    }

    .deposit-info-subtitle {
        font-family: 'FuturaPT-Demi';
        font-size: 12px;
        color: #6c7293;
    }

    .deposit-info-text {
        font-family: 'FuturaPT-Book';
        font-size: 12px;
        color: #565e86;
    }
}

.infobox-container {
    min-height: 120px;
    padding: 19px 16px;
    border-radius: 3px;
    border: solid 0.7px #e8edf4;
    background-color: rgba(233, 237, 255, 0.19);

    .infobox-title-container {
        margin-bottom: 16px;

        .infobox-title {
            border-radius: 12px;
            background-color: #0089fe;
            font-family: 'FuturaPT-Book';
            font-size: 12px;
            color: #ffffff;
            min-height: 18px;
            min-width: 77px;
            letter-spacing: 0.5px;
            padding: 3px 12px;
        }
    }

    .infobox-content {
        div {
            min-width: 50%;
            height: 48px;
            padding-left: 15px;
            padding-bottom: 13px;
            flex: 0;
        }

        .infobox-cell-subtitle {
            font-family: 'FuturaPT-Demi';
            font-size: 12px;
            color: #6c7293;
        }

        .infobox-cell-text {
            font-family: 'FuturaPT-Book';
            font-size: 12px;
            color: #565e86;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

#ui-tabpanel-0.hidden-menu {
    visibility: hidden;
    animation-name: disapearsToLeft;
    animation-duration: 0.3s;
    animation-timing-function: ease;
}

#ui-tabpanel-1.active {
    visibility: visible;
    animation-name: appearsFromRight;
    animation-duration: 0.3s;
    animation-timing-function: ease;
}

#ui-tabpanel-0.active {
    visibility: visible;
    animation-name: appearsFromLeft;
    animation-duration: 0.3s;
    animation-timing-function: ease;
}

#ui-tabpanel-1.hidden-menu {
    visibility: hidden;
    animation-name: disapearsToRight;
    animation-duration: 0.3s;
    animation-timing-function: ease;
}

/* ENTRA */
@-webkit-keyframes disapearsToLeft {
    0% {
        transform: translateX(0%);
        visibility: visible;
        opacity: 1;
    }

    100% {
        transform: translateX(-100%);
        visibility: hidden;
        opacity: 0;
    }
}

@keyframes disapearsToLeft {
    0% {
        transform: translateX(0%);
        visibility: visible;
        opacity: 1;
    }

    100% {
        transform: translateX(-100%);
        visibility: hidden;
        opacity: 0;
    }
}

@-webkit-keyframes appearsFromRight {
    0% {
        transform: translateX(100%);
        visibility: hidden;
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        visibility: visible;
        opacity: 1;
    }
}

@keyframes appearsFromRight {
    0% {
        transform: translateX(100%);
        visibility: hidden !important;
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        visibility: visible !important;
        opacity: 1;
    }
}

/* SALE */
@-webkit-keyframes disapearsToRight {
    0% {
        transform: translateX(0%);
        visibility: visible;
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        visibility: hidden;
        opacity: 0;
    }
}

@keyframes disapearsToRight {
    0% {
        transform: translateX(0%);
        visibility: visible;
        opacity: 1;
    }

    100% {
        transform: translateX(100%);
        visibility: hidden;
        opacity: 0;
    }
}

@-webkit-keyframes appearsFromLeft {
    0% {
        transform: translateX(-100%);
        visibility: hidden;
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        visibility: visible;
        opacity: 1;
    }
}

@keyframes appearsFromLeft {
    0% {
        transform: translateX(-100%);
        visibility: hidden !important;
        opacity: 0;
    }

    100% {
        transform: translateX(0%);
        visibility: visible !important;
        opacity: 1;
    }
}

@media (max-width: @bp-mobile-max) {

    .show-on-desktop {
        display: none;
    }

    .show-on-mobile {
        display: block;
    }

    .kt-content.kt-content-upm {
        padding: 0px !important;
    }

    .rectangle-searchbar {
        background-color: #f4f7fc;
        margin-left: 0 !important;
        margin-right: 0 !important;

        .searchbar-padding {
            padding: 12px 9px;
        }

        input.search-bar-input {
            box-shadow: 0 6px 14px 0 rgba(220, 223, 230, 0.5);
            border-width: 0px;
            font-size: 17.4px;
            width: 100% !important;
            min-height: 46px;
        }
    }

    .btn-upm {
        min-width: 44.6px;
        min-height: 45px;
        padding: 0;


        &.btn-upm-export {

            &.btn-upm-export-only-icon {
                padding-left: 3.5px;
            }
        }
    }

    .add-button-container {
        padding-right: 20.4px !important;
        padding-left: 20px !important;
        margin-bottom: 12px;
        margin-top: 2px;

        h3 {
            margin-bottom: 0;

            span {
                height: 36px;
                font-family: 'FuturaPT';
                font-size: 24px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.5;
                letter-spacing: 0.3px;
                color: #505987;
            }
        }
    }

    .searchbar-container {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .col-upm-6 {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
        position: relative;
        width: 100%;
        min-height: 1px
    }

    .pb-10-m {
        padding-bottom: 10px;
    }

    .pb-24-m {
        padding-bottom: 24px;
    }

    .mt-16-m {
        margin-top: 16px;
    }

    .mt-46-m {
        margin-top: 46px;
    }

    .d-flex-m {
        display: flex !important;
    }

    .back-text-link-container {
        padding: 12px 12px 2px !important;
    }

    .create-edit-title-container {
        margin: 0 auto 25px !important;
        width: 85% !important;

        .create-edit-title {
            font-family: 'FuturaPT-Demi';
            font-size: 27px !important;
        }
    }

    .form-container {
        padding-bottom: 24px !important;

        form {
            margin: 0 auto !important;
            width: 85% !important;

            .form-group {
                width: 100% !important;
                max-width: 100% !important;

                &.mb-43-m {
                    margin-bottom: 43px !important;
                }

                .form-control {
                    width: 100%;

                    &.form-control-checkbox {
                        height: 24px !important;
                    }
                }

                .btn-form-upm {
                    width: 100% !important;
                    margin-left: 0px !important;
                    height: 52px !important;
                    border-radius: 5px !important;

                    &.first-btn {
                        margin-right: 0px !important;
                        margin-bottom: 18px !important;
                        width: 100% !important;
                    }

                    &.create-btn {
                        border: solid 1.8px #77ba27;
                        background-color: #79b835;
                    }

                    &.cancel-btn {
                        border: solid 1.8px #b1b0bb;
                        background-color: #b1b0bb;
                    }

                    span {
                        font-family: 'FuturaPT-Light' !important;
                        font-size: 22.4px !important;
                        letter-spacing: 0.9px !important;
                        color: #ffffff !important;
                    }
                }

            }
        }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 45px;
        height: 24px;
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 19.5px;
        width: 19.5px;
        left: 4px;
        bottom: 2px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.slider {
        background-color: #0089fe;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(18.5px);
        -ms-transform: translateX(18.5px);
        transform: translateX(18.5px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }


    .popup .popuptext {
        bottom: 36px;
        left: 58%;
    }

    .popup .popuptext::after {
        top: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 8px;
        border-style: solid;
        border-color: #e31f26 transparent transparent transparent;
    }


    table {
        &.table {
            &.purchase-info-table {
                tr:first-child {
                    display: none;
                }

                tr:last-child {

                    td {
                        border-top: solid 1px #D8DAE5 !important;
                        padding: 18px 16px !important;
                        font-family: 'FuturaPT-Book' !important;
                        font-size: 19px !important;
                        color: #6c7293 !important;

                        .ui-column-title {
                            padding: 0.4em;
                            min-width: 40%;
                            display: inline-block;
                            margin: -0.4em 1em -0.4em -0.4em;
                            font-weight: bold;
                            font-family: 'FuturaPT-Demi' !important;
                            font-size: 17px !important;
                            color: #6c7293 !important;
                        }
                    }

                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }

    p-tabview {
        .ui-tabview {
            .ui-tabview-panels {
                padding: 12px 0 0 0 !important;
            }
        }
    }

    .ui-table-scrollable-header-box {
        display: none;
    }

    .ui-table-responsive .ui-table-tbody>tr>td {
        text-align: left;
        display: block;
        border: 0 none;
        width: 100% !important;
        box-sizing: border-box;
        float: left;
        clear: left;

        .ui-column-title {
            padding: .4em;
            min-width: 40%;
            display: inline-block;
            margin: -0.4em 1em -0.4em -0.4em;
            font-weight: bold;
        }
    }

    .primeng-datatable-container.upm-table {
        .ui-table {
            table {
                tbody {
                    border-bottom: solid 5px #f0f3ff !important;

                    tr {
                        border-top: solid 0px #f0f3ff !important;
                        border-bottom: solid 5px #f0f3ff !important;

                        td {
                            border-top: solid 1px #f0f3ff !important;
                            padding: 18px 16px !important;
                            font-family: 'FuturaPT-Book' !important;
                            font-size: 19px !important;
                            color: #6c7293 !important;

                            span.ui-column-title {
                                font-family: 'FuturaPT-Demi' !important;
                                font-size: 17px !important;
                                color: #6c7293 !important;
                            }

                            &:last-child {
                                border-bottom: solid 1px #f0f3ff !important;

                                div {
                                    text-align: right !important;
                                }
                            }
                        }

                        &:nth-child(even) {
                            background-color: white !important;
                        }

                        &:last-child {
                            border-bottom: solid 1px #f0f3ff !important;
                        }

                    }
                }
            }

            .ui-table-scrollable-body {
                padding-bottom: 5px !important;

            }
        }

        .primeng-paging-container {
            padding: 16px 18px !important;
            height: 130px;

            .ui-paginator {
                text-align: left !important;

                a {
                    width: 32.6px;
                    height: 32.6px;
                    border: solid 1.6px #f0f3ff;
                    background-color: white;
                    padding-top: 0;
                    margin-right: 5px;

                    span.ui-paginator-icon {
                        color: #979cb5 !important;
                        font-size: 18px;
                    }
                }

                .ui-paginator-pages {
                    .ui-paginator-page {
                        width: 33px;
                        height: 33px;
                        border: solid 1.6px #f0f3ff;
                        padding: 9px !important;
                        background-color: white;
                        margin-right: 5px !important;

                        &.ui-state-active {
                            box-shadow: 0 7px 18px 0 rgba(160, 186, 246, 0.5);
                            background-color: #0089fe !important;
                            border: solid 1.8px #0089fe;
                        }
                    }
                }

                .ui-dropdown {
                    max-width: 70px;
                    left: 28px;

                    .ui-dropdown-label {
                        padding: 0 !important;
                        margin: 0px 0px 0px 10px !important;
                        height: 25px;
                        max-width: 58px;
                        font-family: 'FuturaPT-Book' !important;
                        font-size: 18.1px !important;
                        color: #656c90 !important;
                    }

                    .ui-dropdown-trigger-icon {
                        top: 57%;
                        left: 45%;
                    }
                }

            }

            .total-records-count {
                bottom: -20px;
            }
        }
    }


}

@media (min-width: @bp-desktop-min) and (max-width: @bp-desktop-max) {

    .show-on-desktop {
        display: block;
    }

    .show-on-mobile {
        display: none;
    }

    .rectangle-searchbar {
        display: -webkit-box;
        display: flex;
        display: -ms-flexbox;
    }

    .col-upm-6 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 50% !important;
        flex: 0 0 50% !important;
        max-width: 50% !important;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .create-edit-title-container {
        margin: 0 auto !important;
        max-width: 371px !important;

        .create-edit-title {
            font-family: 'FuturaPT-Demi';
            font-size: 18.5px !important;
            letter-spacing: 0px !important;
            color: #242240 !important;

            &.create-asset-title {
                font-size: 16px !important;
                text-align: center;
            }
        }

        &.fluid {
            max-width: 680px !important;
        }
    }

    .kt-content.kt-content-upm {
        .subtitle-container {
            padding: 36px 36px 0 36px !important;

            a.back-text-link {
                font-family: 'FuturaPT-Book' !important;
                font-size: 19px !important;
                letter-spacing: 0.5px !important;
                color: #6c7293 !important;

                img {
                    margin-right: 4px !important;
                }

                &:hover {
                    text-decoration: none !important;
                }
            }
        }
    }

    .form-container {
        //padding-bottom: 96px !important;

        .form-container {
            //prevent doblue bottom padding on nested form containers (when parent and child components include this class).
            padding-bottom: 0px !important;
        }


        .max-width-container {
            max-width: 680px !important;
            margin: auto;

            .col {
                padding-left: 0;
                padding-right: 0;
            }
        }

        form {
            width: 371px;

            .form-group {
                max-width: 371px !important;
                margin-bottom: 17px !important;

                &.mb-56-d {
                    margin-bottom: 56px !important;
                }

                label {
                    font-family: 'FuturaPT-Demi' !important;
                    font-size: 12px !important;
                    margin-bottom: 2px;
                    color: #6c7293 !important;

                    .optional-input {
                        font-family: 'FuturaNew-Obl';
                        font-size: 11px !important;
                        font-weight: 500 !important;
                        letter-spacing: 0.5px !important;
                    }
                }

                .form-control {
                    padding: 9px 9px 9px 9px !important;
                    border-radius: 3.6px;
                    border: solid 0.7px #d1dbeb;
                    background-color: #ffffff;
                    width: 100%;
                    height: 34px;
                    font-family: 'FuturaPT-Book';
                    font-size: 14px !important;
                    color: #565e86 !important;

                    &.disabled,
                    &[disabled] {
                        border: solid 0.7px #d1dbeb !important;
                        background-color: #f9fbff !important;
                        color: #727d89 !important;
                    }

                    &:focus {
                        border-color: #0284fe;
                    }

                    &.select-form {
                        padding-bottom: 6px !important;
                    }
                }

                .btn-form-upm {
                    width: calc(50% - 13px) !important;
                    margin-left: 0px !important;
                    height: 34px !important;
                    border-radius: 5px !important;
                    border-width: 1.3px;
                    padding: 0px !important;


                    &.first-btn {
                        margin-right: 13px;
                        width: 50% !important;
                    }

                    &.create-btn {
                        border: solid #77ba27;
                        background-color: #79b835;

                        &:hover {
                            border: solid #7cc229;
                            background-color: #7ec037;
                        }

                        &:focus {
                            border: solid #6bb121;
                            background-color: #6bb121;
                        }

                        &.create-btn-ready {
                            border: solid 2px #77ba27 !important;

                            span {
                                font-family: 'FuturaPT-Book' !important;
                            }

                            .icon-create-btn-ready {
                                &::before {
                                    bottom: 1px !important;
                                    position: absolute !important;
                                    right: 0px !important;
                                    font-size: 10px;
                                }
                            }
                        }

                    }


                    &.next-step-btn {
                        border-radius: 5px;

                        &:disabled {
                            opacity: 0.25 !important;
                            border-radius: 5px !important;
                        }
                    }

                    span {
                        font-family: 'FuturaPT-Light' !important;
                        font-size: 15px !important;
                        letter-spacing: 0.9px !important;
                    }
                }

                .back-to-panel-link {
                    font-family: 'FuturaPT-Light';
                    font-size: 16px !important;
                    font-weight: normal !important;
                    text-align: center;
                    color: #6c7293 !important;
                    text-decoration: underline !important;
                    margin-top: 22px !important;
                }
            }
        }
    }

    &.form-2-cols {

        .max-width-container,
        .create-edit-title-container.fluid {
            max-width: 730px !important;
        }

        // .form-2-cols-column {
        //     padding-left: 30px;
        //     padding-right: 30px;
        // }
    }

    .switch {
        position: relative;
        display: inline-block;
        width: 60px;
        height: 34px;
    }

    .switch input {
        opacity: 0 !important;
        width: 0 !important;
        height: 0 !important;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }

    .slider:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: white;
        -webkit-transition: .4s;
        transition: .4s;
    }

    input:checked+.slider {
        background-color: #0089fe;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
    }

    .slider.round {
        border-radius: 34px;
    }

    .slider.round:before {
        border-radius: 50%;
    }

    .pr-0-d {
        padding-right: 0px;
    }

    .pt-11-d {
        padding-top: 11px !important;
    }

    .pb-16-d {
        padding-bottom: 16px;
    }

    .pb-20-d {
        padding-bottom: 20px;
    }

    .pb-30-d {
        padding-bottom: 30px;
    }

    .pb-36-d {
        padding-bottom: 36px;
    }

    .pb-45-d {
        padding-bottom: 45px;
    }

    .pb-70-d {
        padding-bottom: 70px;
    }

    .pr-30-d {
        padding-right: 30px !important;
    }

    .pl-30-d {
        padding-left: 30px !important;
    }

    .ma-d {
        margin: auto;
    }

    .mt-22-d {
        margin-top: 22px;
    }

    .mt-34-d {
        margin-top: 22px;
    }

    .mt-47-d {
        margin-top: 47px;
    }

    .mt-68-d {
        margin-top: 68px;
    }

    .mt-84-d {
        margin-top: 84px;
    }

    .mb-56-d {
        margin-bottom: 56px;
    }

    .btn-upm {
        &.btn-asset {
            padding: 6px 23px 6px 17px !important;

            img {
                width: 15px;
                height: 15px;
                object-fit: contain;
            }
        }
    }

    .kt-subheader {

        &.kt-subheader-upm {
            padding: 0px 0px 11px 0px !important;
        }

        .kt-subheader__title {
            height: 33px;
            font-family: 'FuturaPT' !important;
            font-size: 16.8px !important;
            font-weight: 500 !important;
            letter-spacing: 1.5px !important;
        }
    }

    .primeng-datatable-container.upm-table {
        .ui-table {
            table {
                thead {
                    tr {
                        th {
                            background-color: transparent !important;
                            font-size: 10.2px !important;
                            color: #6c7293 !important;
                            padding: 4px 24px !important;

                            i.ui-sortable-column-icon {
                                width: 10px;

                                &::before {
                                    content: "\e902";
                                    font-size: 8px;
                                    top: 11px;
                                    position: absolute;
                                }
                            }

                            &:first-child {
                                padding-left: 36px !important;
                            }
                        }
                    }
                }

                tbody tr td {
                    font-family: 'FuturaPT-Book' !important;
                    font-size: 12.2px !important;
                    color: #565e86 !important;
                    letter-spacing: 0px !important;
                    padding: 8px 24px !important;

                    &:first-child {
                        padding-left: 36px !important;
                    }

                    &.d-flex {
                        height: 40px;
                    }
                }

            }

            .ui-table-scrollable-body {
                padding-bottom: 30px !important;

            }
        }

        .primeng-paging-container {
            padding: 12px 36px !important;

            .ui-paginator {
                text-align: left !important;
                height: 25px;

                a {
                    width: 25px;
                    height: 25px;
                    border: solid 1.6px #f0f3ff;
                    background-color: white;
                    padding-top: 0;
                    margin-right: 8.6px;
                    min-width: initial;
                    font-size: 10.8px;

                    span.ui-paginator-icon {
                        color: #979cb5 !important;
                        font-size: 10.8px;
                        margin-top: -4px;
                        margin-left: -5px;
                    }
                }

                .ui-paginator-pages {
                    .ui-paginator-page {
                        width: 25px;
                        height: 25px;
                        border: solid 1.6px #f0f3ff;
                        padding: 5px !important;
                        background-color: white;
                        color: #979cb5 !important;
                        margin-right: 8.6px !important;

                        &.ui-state-active {
                            box-shadow: 0 7px 18px 0 rgba(160, 186, 246, 0.5);
                            background-color: #0089fe !important;
                            border: solid 1.8px #0089fe;
                            color: white !important;
                            padding: 4px 1px 3px 1px !important;
                        }
                    }
                }

                /*.ui-dropdown {
                    border-radius: 3px;
                    border: solid 1px #ced2e5;
                    height: 25px;
                    position: absolute;
                    right: 97px;
                    bottom: 16px;
                    max-height: 27px;
                    min-width: 57px;

                    .ui-dropdown-label {
                        padding: 0 !important;
                        margin: 4px 0px 0px 10px !important;
                        height: 15px;
                        max-width: 28px;
                        font-family: 'FuturaPT-Book' !important;
                        font-size: 10.8px !important;
                        color: #656c90 !important;
                    }

                    .ui-dropdown-trigger-icon {
                        top: 57%;
                        left: 20%;
                        font-size: 10.8px;
                    }

                    .ui-corner-right {
                        width: 14px;
                    }

                    .ui-dropdown-items-wrapper {
                        min-width: 55px !important;

                        span.ng-star-inserted {
                            font-size: 10.8px;
                        }
                    }
                }*/

            }

            .total-records-count {
                float: right !important;
                font-family: 'FuturaPT-Book' !important;
                font-size: 10.8px !important;
                color: #656c90 !important;
                bottom: 19px;
                left: -5px;
            }
        }
    }

    .vertical-state-line {
        height: 19px !important;
        margin-left: 8px;
        width: 5px;
    }

    .btn-upm {
        min-height: initial;
        border-radius: 100px;
        padding: 6px 16px 5px 8px;

        &.btn-upm-export {
            color: #79b835;
            background-color: white;
            border: solid 1.2px #79b835;
            box-shadow: 0 5px 10px 0 rgba(157, 200, 109, 0.62);
            cursor: pointer;

            &.disabled,
            &[disabled] {
                opacity: 0.65;
            }

            .excel-icon {
                width: 16px;
                height: 16px;
                object-fit: contain;
                margin-right: 5px;
            }

            span {
                color: #79b835;
                white-space: nowrap;
            }
        }

        &.btn-upm-green {
            border: solid 1.1px #77ba27;
            box-shadow: 0 5px 10px 0 rgba(157, 200, 109, 0.62);

            &:hover {
                cursor: pointer;
                box-shadow: 0 5px 9px 0 rgba(157, 200, 109, 0.62);
                border: solid 1.1px #7cc229;
            }

            &:focus {
                box-shadow: 0 4px 10px 0 rgba(166, 196, 155, 0.5);
                border: solid 1.1px #69b304;
            }
        }

        &.btn-upm-action {
            min-width: 90px;
            min-height: 22px;
            padding: 6px 12px;
            margin-right: 10px;

            span {
                font-size: 9.7px !important;
                letter-spacing: 0px;
                white-space: nowrap;
            }
        }

        span {
            font-family: 'FuturaPT-Light';
            font-size: 13.4px !important;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0.5px;
        }

        img {
            width: 13.8px;
            height: 13.8px;
            object-fit: contain;
        }

        &.btn-asset {
            min-height: 31px;
        }
    }

    .pb-30-d {
        padding-bottom: 15px;
    }

    .kt-content.kt-content-upm {
        margin-bottom: 76px;

        .subtitle-container {
            padding: 28px 36px 0 36px !important;

            a.back-text-link {
                font-family: 'FuturaPT-Book' !important;
                font-size: 13.7px !important;
                letter-spacing: 0.5px !important;
                color: #6c7293 !important;

                img {
                    margin-right: 4px !important;
                    width: 12px;
                    height: 9px;
                }

                &:hover {
                    text-decoration: none !important;
                }
            }
        }
    }


    input.search-bar-input {
        width: 100% !important;
        min-height: 29px;
        border-radius: 26px;
        border: solid 1px #d4d8e8;
        padding: 0px 24px 0px 40px;
        font-size: 14px;
        font-family: 'FuturaPT-Book';
        line-height: normal;
        color: #9aa3b3;
        letter-spacing: 0.5px;
        background-image: url(assets/common/images/upm-icons/search-icon.svg);
        background-repeat: no-repeat;
        background-position: 16px 8px;
        background-size: 12px;

        &::placeholder {
            color: rgba(154, 163, 179, 0.82)
        }

        &:focus {
            background-image: url(assets/common/images/upm-icons/search-icon-active.png) !important;
            // background-repeat: no-repeat;
            background-position: 16px 8px;
            border: solid 1px #0089fe;
            color: rgba(73, 80, 87, 0.82);

            &::placeholder {
                color: rgba(73, 80, 87, 0.82);
            }
        }
    }

    .col-upm-6 {
        -webkit-box-flex: 0 !important;
        -ms-flex: 0 0 50% !important;
        flex: 0 0 50% !important;
        position: relative;
        width: 100%;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;

        &.add-button-container {
            max-width: 150px !important;
        }
    }

    .popup {
        .filters-popup {
            z-index: 2;
            border-radius: 7.2px;
            box-shadow: 0 9px 10px 0 rgba(157, 184, 226, 0.27);
            border: solid 1px rgba(152, 155, 190, 0.3);
            margin-left: 0px;
            padding: 14px 10px;
            width: 135px;
        }
    }

    p-checkbox {
        padding-bottom: 11px;

        &:last-child {
            padding-bottom: 0px;
        }

        .ui-chkbox.ui-widget {
            width: 12.5px !important;
            height: 12.5px !important;
            box-shadow: 0 0 8px 0 rgba(191, 203, 209, 0.27) !important;

            .ui-chkbox-box {
                opacity: 1 !important;
                width: 12.5px !important;
                height: 12.5px !important;
                border: solid 1px #8790b7 !important;

                &.ui-state-active {
                    border-radius: 3px !important;
                    border: solid 0px #8790b7 !important;
                    background-color: #0089fe;

                    .ui-chkbox-icon {
                        top: 2px;
                        left: 0px;
                        font-size: 9px;
                    }
                }

                .ui-chkbox-icon {
                    color: white;
                }
            }
        }

        .ui-chkbox-label {
            margin-left: 6px;
            font-family: 'FuturaPT';
            font-size: 10.8px;
            color: #6c7293;

            &.ui-label-active {
                font-family: 'FuturaPT-Demi';
            }
        }

        &:last-child {
            padding-bottom: 0px !important;
        }
    }

    .kt-btn--icon.kt-btn--icon-only {
        width: 24px !important;
        height: 24px !important;
    }

    img.trash,
    img.edit {
        width: 11.5px;
        height: 12px;
    }

    .switch {
        width: 38px;
        height: 21px;
    }

    .slider:before {
        height: 17px;
        width: 17px;
        left: 2px;
        bottom: 2px;
    }

    input:checked+.slider:before {
        -webkit-transform: translateX(17px);
        -ms-transform: translateX(17px);
        transform: translateX(17px);
    }

    .state-filter {
        width: 11px !important;
        min-width: 11px !important;
        background-size: 11px 7px;
        cursor: pointer;
    }

    .state-filter-active {
        background-size: 11px 7px;
    }

    .col-upm-6.add-button-container {
        max-width: 150px !important;
    }

    .select_arrow {
        top: 12px;
        right: 14px;
        width: 8px;
        height: 8px;
        border-width: 0 1px 1px 0;
        padding: 3px;
    }

    p-calendar {
        &.warning-alert-input-date {
            .ui-calendar {
                border: solid 0.7px #e31f26 !important;
            }
        }

        &.disabled-input-date {
            .ui-calendar {
                border: solid 0.7px #d1dbeb !important;
                background-color: aliceblue !important;
            }
        }

        .ui-button-text {
            height: 100%;
        }

        .ui-calendar {
            width: 100%;
            height: 34px;
            border: solid 0.7px #d1dbeb;
            border-radius: 3.6px;
            -webkit-transition: .2s;
            transition: .2s;

            input {
                padding: 9px !important;
                border: solid 0px #d1dbeb;
                background-color: #ffffff;
                width: calc(100% - 45px);
                margin-left: 1px;
                height: 30px;
                font-family: 'FuturaPT-Book';
                font-size: 14px !important;
            }

            .ui-calendar-button {
                background-color: transparent !important;
                width: 44px !important;
                background: url(/assets/common/images/upm-icons/date-input.png) 18px 7.5px no-repeat;
                background-size: 16px;
                border: 0px !important;

                .ui-button-icon-left {
                    display: none !important;
                }

                &:focus {
                    box-shadow: none !important;
                }
            }

            .ui-datepicker {
                border-radius: 12px !important;
                box-shadow: 0 9px 10px 0 rgba(157, 184, 226, 0.27) !important;
                min-width: initial;
                right: 0px !important;
                left: unset !important;
            }

            .ui-datepicker-calendar {
                font-size: 11px;

                tbody {

                    td {
                        padding: 0px 3px;

                        a {
                            width: 25px !important;
                            height: 25px !important;
                            font-size: 9.7px !important;
                            margin: auto !important;
                            border-radius: 50% !important;
                            font-weight: 500 !important;
                            line-height: 15px !important;

                            &:hover {
                                background-color: transparent !important;
                            }


                            &.ui-state-active {
                                background-color: #0089fe !important;
                                box-shadow: 0 7px 18px 0 rgba(160, 186, 246, 0.5) !important;
                                color: white !important;
                            }
                        }

                        span {
                            width: 25px !important;
                            height: 25px !important;
                            font-size: 9.7px !important;
                            margin: auto !important;
                            line-height: 15px !important;
                        }

                        &.ui-datepicker-today {
                            a {
                                &:hover {
                                    background-color: #d0d0d0 !important;
                                }
                            }
                        }

                    }
                }
            }
        }

        &.ui-inputwrapper-focus {
            .ui-calendar {
                -webkit-transition: .2s;
                transition: .2s;
                border-color: #0284fe;

                .ui-calendar-button {
                    background: url(/assets/common/images/upm-icons/date-input-active.png) 18px 7.5px no-repeat;
                    background-color: transparent !important;
                    width: 44px !important;
                    background-size: 16px;
                    border: 0px !important;
                }
            }
        }
    }

    p-tabview {
        .ui-tabview-nav {
            margin: 0 auto !important;
            max-width: 680px !important;
            border-bottom: solid 1px #cfd4e9 !important;
            padding-left: 12px !important;
            border-radius: 0 !important;

            li {
                font-family: 'FuturaPT' !important;
                letter-spacing: 1px !important;
                background-color: white !important;
                border: none !important;
                outline: none !important;
                font-size: 11px;

                &.ui-state-disabled {
                    opacity: 1 !important;
                }

                a {
                    padding: 8px 19px !important;
                    padding-bottom: 7px;

                    span {
                        font-weight: 500 !important;
                        color: #6c7293 !important;
                        opacity: 0.45;
                    }
                }

                &.ui-state-active {
                    border: none !important;
                    // border-bottom: solid 1px #cfd4e9 !important;
                    margin-bottom: 0 !important;

                    a {
                        span {
                            border-bottom: 3px solid #0089fe !important;
                            color: #5a5f7a !important;
                            padding-bottom: 6px;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .ui-tabview-panels {
            margin: 0 auto !important;
            max-width: 680px !important;
            border: none !important;
            padding: 34px 0 0 0 !important;

        }
    }

    #p-table-assets {
        .ui-widget-header {
            border: solid 1px #e8edf4 !important;
        }

        .ui-table-scrollable-body {
            padding-bottom: 0 !important;
        }

        .ui-table-tbody {
            border: solid 1px #e8edf4 !important;
        }

        .ui-table {
            table thead tr th {
                font-family: 'FuturaPT-Demi' !important;
                padding: 7px 8px !important;
                letter-spacing: 0.1px !important;

                .second-title {
                    font-family: 'FuturaPT-Light';
                    font-size: 10.8px;
                    color: #6c7293
                }
            }

            tbody tr td {
                font-family: 'FuturaPT-Light';
                font-size: 13.7px !important;
                letter-spacing: 0px !important;
                padding: 8px 9px !important;
            }
        }
    }

    .warning-alert {
        padding: 5px !important;
        font-family: 'FuturaPT';
        font-size: 11px;
        font-weight: 500;
        line-height: 1.33;
        color: #e31f26;
        display: block;
    }

    .warning-alert-input {
        border: solid 0.7px #e31f26 !important;
    }

    textarea {
        min-height: 51px !important;
    }
}

.received-green {
    color: #619f1d;
}

.rejected-red {
    color: #e31f26;
}

.right-arrow {
    -moz-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    filter: FlipH;
    -ms-filter: "FlipH";
}

.left-arrow,
.right-arrow {
    margin: 0 2px 0 -2px;
}

.hide {
    display: none;
}

.show {
    display: block;
}

.show-animated {
    // display: block !important;
    -webkit-animation: fadeIn 0.5s forwards;
    animation: fadeIn 0.5s forwards;
}

.hide-animated {
    -webkit-animation: fadeOut 0.5s forwards;
    animation: fadeOut 0.5s forwards;
}

.vertical-divider {
    width: 1px;
    height: 23px;
    border: solid 0.5px #d0d2d9;
    opacity: 0.46;
    margin-left: 8px;
    margin-right: 8px;
}

.language-login-selector {
    font-family: 'FuturaPT-Book';
    font-size: 14px;
    color: #565e86;
    opacity: 0.46;
    cursor: pointer;
}

.language-login-selector-active {
    color: #6c7293;
    opacity: 1;
}

.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--active > .kt-menu__link {
    background-color: #202020 !important;
}
.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--active > .kt-menu__link .kt-menu__link-text {
    color: #ffffff !important;
}

.kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--active {

    .kt-menu__link {
        background-color: #ffffff !important;
        color: #202020 !important;
    }

    .kt-menu__link-text, .kt-menu__link-icon {
        //background-color: #ffffff  !important;
        color: #202020 !important;
    }
}

.kt-menu__link-icon {

    .ng-star-inserted {
        padding: 10%;
    }
}

.kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active) {
    .kt-menu__link .kt-menu__link-text, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active) > .kt-menu__link .kt-menu__link-text {
        color: #202020 !important;
    }

    .kt-menu__link, .kt-header-menu .kt-menu__nav > .kt-menu__item .kt-menu__submenu > .kt-menu__subnav > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active) > .kt-menu__link {
        background-color: #ddddde !important;
    }
} 

.kt-header-menu .kt-menu__nav > .kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active) > .kt-menu__link .kt-menu__link-icon, .kt-header-menu .kt-menu__nav > .kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active) > .kt-menu__link .kt-menu__link-icon {
    color: #ffffff !important;;
}


.popupcustom{
    color: #ffffff !important;
    background-color: #77ba27 !important;
}

.fixed-bottom.footer-upm.kt-footer__bottom{
    padding:1rem 0;
}

#kt_footer{
    height: 60px;
    min-height: 60px;
    background: #fff;
    -webkit-box-shadow: -2px -8px 41px -14px rgba(202,210,222,0.61);
    box-shadow: -2px -8px 41px -14px rgba(202,210,222,0.61);

    .kt-footer__bottom{
        background: #fff;
    }
    .kt-footer__copyright{
        height: 16px;
        font-family: 'FuturaPT-Book';
        font-size: 12.4px !important;
        font-weight: normal;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: 0.5px !important;
        color: #5d6f8e !important;
        font-weight: 500 !important;
        text-align: right;
    }
}

.kt-portlet.kt-portlet--mobile{
    box-shadow: #eaeaea 0px 0px 7px 9px;
}

.kt-header--minimize{
    img.brandLogoUpm{
        max-height: 60px;
    }
}

.kt-footer__bottom{
    width: 100%;

    .kt-footer__copyright{
        float: right;
    }
}
